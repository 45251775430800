/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */

const TECHNICAL_COOKIES = [
  'X-MARKET',
  'x-country',
  'user_id',
  'locale',
  'currency',
  '_session_id',
  'accessibility_mode',
  'browsing_session',
  'clean_timestamp',
  'logged_messages',
  'my_iberostar_discount',
  'my_iberostar_discount_unchecked_by_user',
  'next_opening_hotels_popup_displayed',
  'notifications_shown',
  'pending_favourite_hotel_code',
  'remove_browsing',
  'resident',
  'show_myibe_discount',
  'seen_auto_pop_up',
  'user_web_name',
  'cookies_consent',
  'check_user_id',
  'fastbooking_is_search',
  'userLogin',
  'logged_in_microsites',
  'no_lb',
  'cp_no_prom',
  'cp_no_prom_partpdv',
  'cp_no_prom_name',
  'cp_no_prom_image',
  'cp_expires_at',
  'cp_promotional',
  'campaign_cp_no_prom',
  'campaign_cp_no_prom_origin',
  'cp_to_crs',
  'shared_last_searches',
  'auth0_postregister_form',
  'user_metadata',
  'language',
  'fia_user_id',
  'fiacp'
]

const ANALYTICS_COOKIES = [
  'hotel_headquarter_origin',
  'hotel_headquarter_final',
  'last_access',
  'page_site_origin',
  'promotion_creative',
  'user_points',
  'user_level',
  'visitor_has_reservation',
  'visitor_type',
  'visitor_is_buyer',
  'visitor_session_id',
  'visitor_session_id_expire',
  'visitor_iberostar',
  'visitor_recurrence',
  'visitor_session_count',
  'crm_id',
  'affId',
  'network',
  'idCliente',
  'CampaignId',
  'j',
  'e',
  'l',
  'u',
  'jb',
  'mid',
  'login_success',
  'custom_var',
  'idevice_id',
  'idevice_type',
  'fastbooking_data_utag',
  'update_utag_data',
  'login_continue_as_google_user',
  'register_success_google_one_tap',
  'login_success_google_one_tap',
  'register_error',
  'register_success',
  'login_error',
  'agency_group_name',
  'business_agent_code',
  'business_code',
  'business_name',
  'business_type',
  'utag_user',
  'register_social_network_name',
  'google_one_tap_login',
  'social_network_name',
  'prelead_type',
]
//'visitor_internal_market',

const PERSONALIZATION_COOKIES = [
  'exit_partner',
  'visitor_is_repeater',
  'cp',
  'cp_name',
  'cp_image',
  'cp_partpdv',
  'campaign_cp',
  'campaign_cp_origin',
  'cjevent',
  'test_reb_header',
  'token_partner',
]

const REMOVABLE_COOKIES = [
  'visitor_session_id_expire',
  'hotel_headquarter_origin',
  'hotel_headquarter_final',
  'fastbooking_data_utag',
  'cp',
  'cp_expires_at',
  'cp_image',
  'cp_name',
  'cp_partpdv',
  'cp_promotional',
  'utag_user',
  'fia_user_id',
  'fiacp',
  'idevice_type',
  'idevice_id',
  'ihg_data',
  'register_error',
  'register_success',
  'update_utag_data',
  'cp_no_prom',
  'cp_no_prom_partpdv',
  'cp_no_prom_name',
  'cp_no_prom_image',
  'campaign_cp_no_prom',
  'cp_to_crs',
  'token_partner',
  'login_success',
  'login_error',
  'login_continue_as_google_user',
  'register_success_google_one_tap',
  'register_social_network_name',
  'login_success_google_one_tap',
  'register_error',
  'register_success',
  'social_network_name',
  'google_one_tap_login',
  'auth0_postregister_form',
  'prelead_type',
]

export function readCookie(name) {
  const cookieArr = document.cookie.split(';')
  const found = cookieArr.find(c => c.split('=')[0].trim() === name)
  return found?.split('=')[1] // returns the value of the cookie if found, undefined otherwise
}

export function setCookie(
  c_name,
  value,
  exdays,
  path = null,
  domain = null,
  secure = null,
  httponly = null,
  same_site = null,
  callback = null
) {
  const cookie = cookieOptions(exdays, path, domain, secure, httponly, same_site)
  cookie['name'] = c_name
  cookie['value'] = value
  return setCookies(new Array(cookie), callback)
}

export function setCookies(cookies, callback) {
  const valid_cookies = []
  let is_cookies_consent = false

  if (cookies !== null && cookies.length > 0) {
    cookies.forEach(function (cookie) {
      Object.assign(
        cookie,
        cookieOptions(
          cookie['expires'],
          cookie['path'],
          cookie['domain'],
          cookie['secure'],
          cookie['httponly'],
          cookie['same_site']
        )
      )
      if (cookieIsAccepted(cookie)) {
        valid_cookies.push(cookie)
        is_cookies_consent = is_cookies_consent || cookie['name'] === is_cookies_consent
      }
    })
  } else if (callback) {
    callback()
  }

  if (valid_cookies.length > 0) {
    const params = { cookies: JSON.stringify(valid_cookies) }
    const body = Object.keys(params)
      .map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      })
      .join('&')
    return fetch('/cookies/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body,
    }).then(() => {
      if (callback) {
        callback()
      }
    })
  }
}

export function deleteCookies(cookie_names, callback) {
  const cookies = []
  cookie_names.forEach(name => {
    if (typeof readCookie(name) === 'undefined') {
      return
    }

    if (REMOVABLE_COOKIES.includes(name)) {
      cookies.push({ name, value: '-1', expires: '-1' })
    } else {
      cookies.push({ name, value: '-1', expires: '-1', domain: IB.cookieManager.getDomainForCookie(name) })
    }
  })
  setCookies(cookies, callback)
}

export function hasUserAcceptedCookies() {
  return readCookie('cookies_consent') === 'true'
}

function isDebugPresent() {
  return document.documentElement.dataset.debug === 'true'
}

function isDebugConsolePresent() {
  return document.documentElement.dataset['debugConsole'] === 'true'
}

function cookieOptions(exdays, path = null, domain = null, secure = null, httponly = null, same_site = null) {
  const expires = exdays === null || exdays === undefined ? 365 : exdays
  return {
    expires,
    path,
    domain,
    secure,
    httponly,
    same_site,
  }
}

function getGroupOfCookie(cookie_name) {
  if (TECHNICAL_COOKIES.includes(cookie_name)) {
    return 'technical'
  }
  if (ANALYTICS_COOKIES.includes(cookie_name)) {
    return 'analytics'
  }
  if (PERSONALIZATION_COOKIES.includes(cookie_name)) {
    return 'personalization'
  }
  return 'unknown'
}

function groupIsAccepted(group) {
  let ret = false
  const state = typeof utag !== 'undefined' && typeof utag.gdpr !== 'undefined' ? utag.gdpr.getConsentState() : []

  //Permitimos siempre en local. Si no cambiar ese valor en application.html.erb
  if (isDebugPresent()) {
    return true
  }

  //Permitimos las técnicas. El resto hay que revisar el estado
  if (group === 'technical' || state === 1 || state === '1') {
    return true
  }

  if (state === -1 || state === '-1' || state.length === 0) {
    return true
  }

  if (state !== 0 && state !== '0') {
    state.forEach(s => {
      if (s['name'] === group && (s['ct'] === 1 || s['ct'] === '1')) {
        ret = true
      }
    })
  }

  return ret
}

let blocked_cookies = []

function manageBlockedCookie(cookie) {
  const index = blocked_cookies.findIndex(c => c['name'] === cookie['name'])
  if (index === -1) {
    blocked_cookies.push(cookie)
  } else {
    blocked_cookies[index] = cookie
  }
  if (isDebugConsolePresent()) {
    console.log(`Blocked cookie: ${cookie['name']}`)
  }
}

function cookieIsAccepted(cookie) {
  const group = getGroupOfCookie(cookie['name'])

  //Permitimos siempre en local. Si no cambiar ese valor en application.html.erb
  if (isDebugPresent()) {
    return true
  }

  //Permitimos siempre borrar cookies. Para añadir comprobamos el grupo
  if (
    cookie['expires'].toString().includes('Jan 01 1970') ||
    cookie['expires'].toString() === '-1' ||
    groupIsAccepted(group)
  ) {
    return true
  }
  manageBlockedCookie(cookie)
  return false
}

function manageConsentChanged(call_ajax_utag = true, call_set_partner = true) {
  //Try to send the cookies that were stopped
  const current_blocked_cookies = blocked_cookies
  blocked_cookies = []
  setCookies(current_blocked_cookies, function () {
    //Try to load utag_data
    if (call_ajax_utag && typeof ajax_utag_callback !== 'undefined') {
      ajax_utag_callback()
    }

    //Try to load partner
    if (call_set_partner && typeof IB.partner !== 'undefined') {
      IB.partner.showPartnerLogo()
    }
  })
}

function getDomainForCookie() {
  return `.${document.domain.split('.').slice(-2).join('.')}`
}

window.tealiumSetCookiesConsent = () => {
  let call_ajax_utag = true
  let call_set_partner = true
  //Check if we have to delete cookies
  let state = typeof utag !== 'undefined' && typeof utag.gdpr !== 'undefined' ? utag.gdpr.getConsentState() : []

  //Permitimos siempre en local. Si no cambiar ese valor en application.html.erb
  if (isDebugPresent()) {
    state = 1
  }
  const cookies_to_delete = []
  if (state === 0 || state === '0') {
    if (isDebugConsolePresent()) {
      console.log('Deleting analytics & personalization cookies...')
    }
    ANALYTICS_COOKIES.forEach(s => {
      cookies_to_delete.push(s)
    })
    PERSONALIZATION_COOKIES.forEach(s => {
      cookies_to_delete.push(s)
    })
    //Delete utag
    call_ajax_utag = false
    sessionStorage.setItem('utag_user', '')
    //Delete partner
    call_set_partner = false
    if (typeof IB.partner !== 'undefined' && readCookie('cp_promotional') !== 'false') {
      IB.partner.hidePartnerLogo()
    }
  } else if (state !== 1 && state !== '1') {
    state.forEach(s => {
      if (s['name'] === 'analytics' && (s['ct'] === 0 || s['ct'] === '0')) {
        if (isDebugConsolePresent()) {
          console.log('Deleting analytics cookies...')
        }
        ANALYTICS_COOKIES.forEach(s => {
          cookies_to_delete.push(s)
        })
        call_ajax_utag = false
        sessionStorage.setItem('utag_user', '')
      } else if (s['name'] === 'personalization' && (s['ct'] === '0' || s['ct'] === 0)) {
        if (isDebugConsolePresent()) {
          console.log('Deleting personalization cookies...')
        }
        PERSONALIZATION_COOKIES.forEach(s => {
          cookies_to_delete.push(s)
        })
        //Delete partner
        call_set_partner = false
        if (typeof IB.partner !== 'undefined' && readCookie('cp_promotional') !== 'false') {
          IB.partner.hidePartnerLogo()
        }
      }
    })
  }
  deleteCookies(cookies_to_delete)
  manageConsentChanged(call_ajax_utag, call_set_partner)
}

function initCookieManager() {
  const check_utag = setInterval(checkUtagDefined, 1000)

  function checkUtagDefined() {
    if (typeof utag !== 'undefined' && typeof utag.gdpr !== 'undefined') {
      clearInterval(check_utag)
      // manageConsentChanged();
      // manageConsentChanged won't delete cookies if they were created in CRS
      tealiumSetCookiesConsent()
    }
  }
}

initCookieManager()
;(function () {
  if (typeof IB === 'undefined') {
    window.IB = {}
  }

  IB.cookieManager = {
    blocked_cookies, // TODO: Potential removal. Not used outside this file.
    groupIsAccepted,
    cookieIsAccepted, // TODO: Potential removal. Not used outside this file.
    manageConsentChanged, // TODO: Potential removal. Not used outside this file.
    setCookies,
    deleteCookies,
    getDomainForCookie, // TODO: Potential removal. Not used outside this file.
  }
})()
